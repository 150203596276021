.Modal-form {
    display: flex;
    flex-direction: column;
    color: white;
}

.Modal-close {
    display: flex;
    justify-content: flex-end;
}

.Modal-close-btn {
    background: none;
    border: none;
    color: white;
}

.Modal-form-content {
    display: flex;
    flex-direction: column;
    color: white;
    max-width: 100%;
    font-size: 24px;
}

.Modal-form-content-input {
    width: 100%;
    font-size: 24px;
    margin-bottom: 1em;
}


.Modal-form-content-btn-box {
    display: flex;
    justify-content: center;
}

.Modal-form-content-btn {
    background-color: dimgray;
    border: none;
    color: white;
    font-size: 24px;box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    padding: 0.5em;
}
