.Footer {
    position: fixed;
    bottom: 0;
    background-color: #161b22;   
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.Footer-img-box {
    padding: 0.5em;
}

.Footer-img {
    height: 2em;
    width: 2em;
}