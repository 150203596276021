.Header {
    position: sticky;
    top: 0;
    z-index: 999;
    display: flex;
    flex-direction: row;
    background-color: #161b22;    
    color: white;
    width: 100%;
    align-items: center;    
}

.Header-logo {
    display: flex;
    flex: 1;
    padding: 1em;
    justify-content: flex-start; 
}

.Header-logo-img {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
}

@media screen and (min-width: 0px) and (max-width: 400px) {
    #Header-name { display: none; }
}

.Header-logo-content {
    display: flex;
    flex-direction: column;    
    flex: 1;
    text-align: left;
    padding-left: 1em;    
    justify-content: space-around;
}

.Header-user {
    text-align: right;
    align-content: center;
    padding: 1em;
}