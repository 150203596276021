.LandingPage {
    color: white;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.LandingPage-posts {
    display: flex;
    flex-direction: row;
    padding: 1em;
}

.LandingPage-side-section {
    flex: 0.3;
}

.LandingPage-main-section {    
    flex: 0.7;
    margin: 0em 0em 2em 0em;
}

.LandingPage-add-post {
    position: fixed;    
    color: dimgray;
    bottom: 10%;
    right: 2%;
    border: none;
    background: none;
}