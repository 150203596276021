.Card {
    display: flex;
    flex-direction: column;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    padding: 1em;
    margin: 0em 0em 2em 0em;
    background-color: rgb(34, 31, 34);
    width: 100%;
}

.Card-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: dimgray;
}

.material-icons {
    font-size: 48px;
}

.Card-user-title {
    font-size: 1.5em;
    margin-bottom: 10px;
}